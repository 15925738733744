







import { computed, defineComponent, PropType } from '@nuxtjs/composition-api';
import WbFormItemError from './form-item-error.vue';

export default defineComponent({
  name: 'FormItemContent',
  components: {
    WbFormItemError,
  },
  props: {
    errors: {
      type: Array as PropType<Array<string | Error>>,
      required: false,
      default: () => [],
    },
    errorClass: {
      type: String,
      required: false,
      default: 'has-error', // Sourced from antd `a-form-item` component
    },
  },
  setup(props) {
    const hasError = computed(() => props.errors.length > 0);
    return { hasError };
  },
});
