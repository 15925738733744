import Vue, { VueConstructor, PluginObject } from 'vue';
import WbBottomSheet from './bottom-sheet.vue';

const sheet: VueConstructor<Vue> & PluginObject<void> = WbBottomSheet as any;
sheet.install = function (vue) {
  vue.component('WbBottomSheet', WbBottomSheet);
};

export { WbBottomSheet };
export default sheet;
