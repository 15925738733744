






































































































































import { Component } from 'vue';
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  ref,
  useRouter,
} from '@nuxtjs/composition-api';
import { Empty } from 'ant-design-vue';
import { tryOnScopeDispose } from '@vueuse/core';
import TheScanZone, { IconConfig, ZoneVariant } from './scan-zone.vue';
import CaseMatch, { MatchAction } from './scan-match.vue';
import ScanCounter from './scan-counter.vue';
import TransportModalProvider, {
  TransportType,
  TransportWorkflowBehaviors,
} from './transport-modal-provider.vue';
import { useAPI } from '~/app/core/api';
import { TickTimer } from '~/app/utils/tick-timer';
import { CaseSummary } from '~/app/models';
import { useModule } from '~/app/core/module-system';
import { BarcodingModule } from '~mod:barcoding';
import { useScanner } from '~mod:barcoding/composables/use-scanner';
import { useTenantConfig } from '~/app/composables/use-tenant-config';

enum ScanState {
  Idle,
  Gathering,
  Validating,
  Match,
  Error,
}

enum ScanMode {
  Single,
  Multiple,
}

const GATHERING_WAIT_MS = 10000;
const UI_RESET_DELAY_MS = 3000;

export default defineComponent({
  name: 'BarcodeScannerToast',
  components: { CaseMatch, TheScanZone, ScanCounter, TransportModalProvider },
  setup() {
    const { features } = useTenantConfig();
    const { scansRequired } = features.barcoding.scanning;

    // #region Process State

    const mode = ref<ScanMode>(
      scansRequired > 1 ? ScanMode.Multiple : ScanMode.Single
    );

    const state = ref<ScanState>(ScanState.Idle);
    const isIdle = computed(() => state.value === ScanState.Idle);
    const isGathering = computed(() => state.value === ScanState.Gathering);
    const isValidating = computed(() => state.value === ScanState.Validating);
    const isMatched = computed(() => state.value === ScanState.Match);
    const isError = computed(() => state.value === ScanState.Error);

    const showUI = computed(() => !isIdle.value);

    const zoneVariant = computed<ZoneVariant>(() => {
      if (isGathering.value) return 'active';
      if (isValidating.value || (isMatched.value && matches.value.length))
        return 'success';
      if (isError.value || (isMatched.value && !matches.value.length))
        return 'error';
      return 'active';
    });

    const iconLeft = computed<string | IconConfig | undefined>(() => {
      if (isError.value) return 'warning';
      if (isGathering.value) return undefined;
      if (isMatched.value && matches.value.length) return 'check-circle';
      if (isMatched.value && !matches.value.length) return 'x-circle';
      return { name: 'circle-notch', class: 'animate-spin' };
    });

    // #endregion

    // #region Error Handling

    const error = ref<string>();
    let errorResetTimer: number | null = null;

    function raiseError(message: string) {
      state.value = ScanState.Error;
      error.value = message;
      errorResetTimer = window.setTimeout(() => reset(), UI_RESET_DELAY_MS);
    }

    function stopErrorResetTimer() {
      if (errorResetTimer === null) return;
      window.clearTimeout(errorResetTimer);
      errorResetTimer = null;
    }

    // #endregion

    // #region Scan gathering

    const scansCaptured = ref<string[]>([]);

    const tickMeasureMs = 10; // The duration in ms to measure a timer "tick"
    const tickTimer = new TickTimer(GATHERING_WAIT_MS, tickMeasureMs);
    const gatheringPercentRemaining = ref(100);
    let gatheringCountdownTimer: number | null = null;

    function startGatheringCountdown(onTimeout?: () => void) {
      if (typeof gatheringCountdownTimer === 'number') {
        // Reset the countdown timer if it's already running
        window.clearInterval(gatheringCountdownTimer);
      }

      let tick = 0;
      gatheringPercentRemaining.value = 100;

      gatheringCountdownTimer = window.setInterval(() => {
        const remainingMs = tickTimer.getTimeRemaining(tick + 1, 'ms');
        if (remainingMs <= GATHERING_WAIT_MS) {
          const percent = (remainingMs / GATHERING_WAIT_MS) * 100;
          gatheringPercentRemaining.value = percent;
        }

        if (++tick * tickMeasureMs >= GATHERING_WAIT_MS) {
          gatheringPercentRemaining.value = 0;
          window.clearInterval(gatheringCountdownTimer!);
          gatheringCountdownTimer = null;
          onTimeout?.();
        }
      }, tickMeasureMs);
    }

    function stopGatheringCountdown() {
      if (typeof gatheringCountdownTimer === 'number')
        window.clearInterval(gatheringCountdownTimer);

      gatheringPercentRemaining.value = 100;
      gatheringCountdownTimer = null;
    }

    // #endregion

    // #region Matching

    const matches = ref<readonly CaseSummary[]>([]);
    let matchResetTimer: number | null = null;

    function startMatchIdleTimer() {
      if (matchResetTimer) clearTimeout(matchResetTimer);
      matchResetTimer = window.setTimeout(() => reset(), UI_RESET_DELAY_MS);
    }

    function stopMatchIdleTimer() {
      if (!matchResetTimer) return;
      window.clearTimeout(matchResetTimer);
      matchResetTimer = null;
    }

    // #endregion

    // #region Scanner Behavior

    const { onScan } = useScanner();

    const api = useAPI();
    async function checkScan(data: string[]) {
      state.value = ScanState.Validating;

      if (mode.value === ScanMode.Multiple) {
        if (data.length < scansRequired) {
          raiseError('Insufficient scans collected. Please try again.');
          return [];
        }

        if (data.length > scansRequired) {
          // Truncate the scan values to the required amount
          data.splice(scansRequired);
        }
      }

      try {
        const matches = await api.cases.identification.matchFieldsAsync(data);
        state.value = ScanState.Match;
        return matches;
      } catch (error) {
        raiseError('An error occurred while validating the scan.');
        return [];
      }
    }

    function reset() {
      state.value = ScanState.Idle;
      matches.value = [];
      scansCaptured.value.splice(0, scansCaptured.value.length);
      stopMatchIdleTimer();
      stopErrorResetTimer();
    }

    const unsubscribe = onScan(async (data) => {
      if ([ScanState.Error, ScanState.Match].includes(state.value)) reset();

      scansCaptured.value.push(data);

      // Deferring the scan check until the required number of scans are collected
      if (
        mode.value === ScanMode.Multiple &&
        scansCaptured.value.length < scansRequired
      ) {
        // Create a timer to update to compute the remaining gathering time left
        startGatheringCountdown(() =>
          raiseError('Scan timeout reached. Please try again.')
        );
        state.value = ScanState.Gathering;
        return;
      }

      stopGatheringCountdown();

      const scanMatches = await checkScan(scansCaptured.value);
      if (scanMatches.length) {
        matches.value = scanMatches;

        await nextTick();
        setupIntersectionObserver();

        return;
      }

      // Reset UI after a delay
      startMatchIdleTimer();
    });

    tryOnScopeDispose(() => {
      unsubscribe();
      reset();
    });

    // #endregion

    // #region Carousel Navigation

    const activeItem = ref(1);

    function updateActiveItem(entries: IntersectionObserverEntry[]) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = Number(entry.target.getAttribute('data-index'));
          if (!isNaN(index)) {
            activeItem.value = index + 1;
          }
        }
      });
    }

    const observerRef = ref<IntersectionObserver | null>(null);

    function setupIntersectionObserver() {
      observerRef.value = new IntersectionObserver(updateActiveItem, {
        root: document.querySelector('.carousel-container'),
        threshold: 0.5, // Consider an item "active" when it's 50% visible
      });

      const carouselItems = document.querySelectorAll('.carousel-item');
      carouselItems.forEach((item, index) => {
        item.setAttribute('data-index', index.toString());
        observerRef.value?.observe(item);
      });
    }

    function cleanupIntersectionObserver() {
      observerRef.value?.disconnect();
    }

    onMounted(() => setupIntersectionObserver());
    onUnmounted(() => cleanupIntersectionObserver());

    function scrollCarousel(direction: 'left' | 'right') {
      const container = document.querySelector('.carousel-container');
      if (container) {
        const scrollAmount = container.clientWidth;
        container.scrollBy({
          left: direction === 'left' ? -scrollAmount : scrollAmount,
          behavior: 'smooth',
        });
      }
    }

    // #endregion

    // #region Transport

    const transportProvider = ref<
      InstanceType<typeof TransportModalProvider>
    >();

    const isTransportModalActive = computed(() => {
      if (!transportProvider.value) return false;

      const types = [
        TransportType.CheckIn,
        TransportType.CheckOut,
        TransportType.Transfer,
      ];

      return types.some(
        (type) => transportProvider.value!.workflows[type].active
      );
    });

    const router = useRouter();
    function goToCase(id: string) {
      router.push(`/case/${id}`);
      reset();
    }

    // Reset scanner and request a data refresh from any features that may be paired with this module
    const module = useModule(BarcodingModule);
    function resetAndRefresh() {
      reset();
      module.requestRefresh();
    }

    const transportBehaviors: TransportWorkflowBehaviors = {
      onComplete: resetAndRefresh,
      identityVerified: true,
    };

    async function transferCase(id: string) {
      await transportProvider.value?.startWorkflow(
        TransportType.Transfer,
        id,
        transportBehaviors
      );
    }

    async function checkInCase(id: string) {
      await transportProvider.value?.startWorkflow(
        TransportType.CheckIn,
        id,
        transportBehaviors
      );
    }

    async function checkOutCase(id: string) {
      await transportProvider.value?.startWorkflow(
        TransportType.CheckOut,
        id,
        transportBehaviors
      );
    }

    // #endregion

    const emptyImageComponent = (Empty as typeof Empty & {
      PRESENTED_IMAGE_SIMPLE: Component;
    }).PRESENTED_IMAGE_SIMPLE;

    return {
      // Process State
      isEnabled: features.barcoding.scanning.enabled,
      mode,
      showUI,
      isIdle,
      isGathering,
      isValidating,
      isMatched,
      isError,
      error,
      zoneVariant,
      iconLeft,
      // Scan gathering
      gatheringPercentRemaining,
      scansCaptured,
      scansRequired,
      // Carousel Navigation
      scrollCarousel,
      activeItem,
      // Matching
      reset,
      matches,
      startMatchIdleTimer,
      stopMatchIdleTimer,
      MatchAction,
      // Transport
      transportProvider,
      isTransportModalActive,
      goToCase,
      transferCase,
      checkInCase,
      checkOutCase,
      // Misc.
      emptyImageComponent,
    };
  },
});
