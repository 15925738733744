import { Type } from 'class-transformer';
import { DateTimeInfo, IsDateTimeInfo } from '../dates';

export class NextOfKinRecord {
  public readonly id?: string;

  public name?: string;

  public relationship?: string;

  public typeId?: string;

  public contactPhoneNumber?: string;

  public contactEmailAddress?: string;

  @IsDateTimeInfo()
  public dateNotified?: DateTimeInfo;

  constructor(args?: NextOfKinRecord) {
    if (!args) return;
    this.id = args.id;
    this.name = args.name?.trim();
    this.relationship = args.relationship?.trim();
    this.typeId = args.typeId;
    this.contactPhoneNumber = args.contactPhoneNumber?.trim();
    this.contactEmailAddress = args.contactEmailAddress?.trim();
    this.dateNotified = args.dateNotified
      ? new DateTimeInfo(args.dateNotified)
      : undefined;
  }
}

export class NextOfKinCollection {
  @Type(() => NextOfKinRecord)
  public records?: NextOfKinRecord[];

  constructor(args?: Partial<NextOfKinCollection>) {
    this.records = args?.records || [];
  }
}
