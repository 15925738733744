var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Teleport',{attrs:{"to":"body"}},[_c('div',{ref:"bottomSheet",staticClass:"bottom-sheet",style:({
      '--overlayColor': _vm.overlayColor,
      '--transitionDuration': _vm.transitionDurationVar,
      '--sheetHeight': _vm.sheetHeightVar,
      '--maxHeight': _vm.maxHeightVar,
      '--translateValue': _vm.translateValueVar,
      '--maxWidth': _vm.maxWidthVar,
    }),attrs:{"id":("bottom-sheet-" + _vm.sheetId),"aria-hidden":!_vm.showSheet,"role":"dialog"}},[_c('transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.overlay && _vm.showSheet),expression:"overlay && showSheet"}],staticClass:"bottom-sheet__overlay",on:{"click":_vm.clickOnOverlayHandler}})]),_vm._v(" "),_c('div',{ref:"bottomSheetContent",class:_vm.sheetContentClasses},[_c('header',{ref:"bottomSheetHeader",staticClass:"bottom-sheet__header"},[_vm._t("header:top",null,{"open":_vm.open,"close":_vm.close}),_vm._v(" "),_vm._t("header:thumb",[_c('div',{ref:"bottomSheetDraggableArea",staticClass:"bottom-sheet__draggable-area"},[_c('div',{staticClass:"bottom-sheet__draggable-thumb"})])],{"open":_vm.open,"close":_vm.close}),_vm._v(" "),_vm._t("header:bottom",null,{"open":_vm.open,"close":_vm.close})],2),_vm._v(" "),_c('main',{ref:"bottomSheetMain",staticClass:"bottom-sheet__main"},[_vm._t("default")],2),_vm._v(" "),_c('footer',{ref:"bottomSheetFooter",staticClass:"bottom-sheet__footer"},[_vm._t("footer")],2)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }