
















































import {
  defineComponent,
  computed,
  nextTick,
  ref,
  onMounted,
} from '@nuxtjs/composition-api';
// @ts-expect-error - Package does not include type definitions
import Teleport from 'vue2-teleport';
import Hammer from 'hammerjs';
import { genId } from '~/app/utils/id';

export interface TouchEvent {
  type: string;
  deltaY: number;
  isFinal: boolean;
  cancelable: boolean;
}

export default defineComponent({
  name: 'BottomSheet',
  components: {
    Teleport,
  },
  props: {
    /** Show an overlay behind the bottom sheet */
    overlay: {
      type: Boolean,
      required: false,
      default: true,
    },
    /** Overlay Color */
    overlayColor: {
      type: String,
      required: false,
      default: '#0000004D',
    },
    /** Max width of the sheet */
    maxWidth: {
      type: [Number, String],
      required: false,
      default: 640,
    },
    /** Max height of the sheet */
    maxHeight: {
      type: [Number, String],
      required: false,
      default: undefined,
    },
    /** Transition duration in seconds */
    transitionDuration: {
      type: Number,
      required: false,
      default: 0.2,
    },
    /** Close sheet on overlay click */
    overlayClickClose: {
      type: Boolean,
      required: false,
      default: true,
    },
    /** Enable swipe to close */
    swipe: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ['opened', 'closed', 'dragging-up', 'dragging-down'],
  setup(props, { emit }) {
    const sheetId = genId();
    const showSheet = ref(false);
    const sheetHeight = ref(0);
    const translateValue = ref(100);
    const isDragging = ref(false);
    const contentScroll = ref(0);

    // #region Refs

    const bottomSheet = ref<HTMLElement | null>(null);
    const bottomSheetHeader = ref<HTMLElement | null>(null);
    const bottomSheetMain = ref<HTMLElement | null>(null);
    const bottomSheetFooter = ref<HTMLElement | null>(null);
    const bottomSheetContent = ref<HTMLElement | null>(null);
    const bottomSheetDraggableArea = ref<HTMLElement | null>(null);

    // #endregion

    const sheetContentClasses = computed(() => [
      'bottom-sheet__content',
      {
        'bottom-sheet__content--fullscreen':
          sheetHeight.value >= window.innerHeight,
        'bottom-sheet__content--dragging': isDragging.value,
      },
    ]);

    // #region CSS Variables

    const transitionDurationVar = computed(
      () => `${props.transitionDuration}s`
    );

    const sheetHeightVar = computed(() =>
      sheetHeight.value && sheetHeight.value > 0
        ? `${sheetHeight.value + 1}px`
        : 'auto'
    );

    const maxHeightVar = computed(() =>
      props.maxHeight
        ? !isNaN(Number(props.maxHeight))
          ? `${props.maxHeight}px`
          : props.maxHeight
        : 'inherit'
    );

    const translateValueVar = computed(() => `${translateValue.value}%`);

    const maxWidthVar = computed(() => `${props.maxWidth}px`);

    // #endregion

    /** Calculate sheet height */
    async function initHeight() {
      await nextTick();
      sheetHeight.value =
        bottomSheetHeader.value!.offsetHeight +
        bottomSheetMain.value!.clientHeight +
        bottomSheetFooter.value!.offsetHeight;
    }

    /** Move sheet while dragging */
    function dragHandler(event: typeof Hammer.Input, type: 'area' | 'main') {
      if (props.swipe) {
        isDragging.value = true;

        const preventDefault = (e: Event) => e.preventDefault();

        if (event.deltaY > 0) {
          if (type === 'main' && event.type === 'panup') {
            translateValue.value = pixelToVh(event.deltaY);
            // @ts-expect-error - HammerJS type definitions are incorrect, cancelable should exist
            if (event.cancelable) {
              bottomSheetMain.value!.addEventListener(
                'touchmove',
                preventDefault
              );
            }
          }

          if (
            type === 'main' &&
            event.type === 'pandown' &&
            contentScroll.value === 0
          ) {
            translateValue.value = pixelToVh(event.deltaY);
          }

          if (type === 'area') translateValue.value = pixelToVh(event.deltaY);

          if (event.type === 'panup') emit('dragging-up');
          if (event.type === 'pandown') emit('dragging-down');
        }

        if (event.isFinal) {
          bottomSheetMain.value?.removeEventListener(
            'touchmove',
            preventDefault
          );

          if (type === 'main')
            contentScroll.value = bottomSheetMain.value!.scrollTop;

          isDragging.value = false;

          if (translateValue.value >= 10) close();
          else translateValue.value = 0;
        }
      }
    }

    onMounted(() => {
      if (!bottomSheetMain.value) {
        console.warn('Bottom sheet main content not found');
        return;
      }

      // Set initial card height
      initHeight();

      window.addEventListener('keyup', (event: KeyboardEvent) => {
        if (!bottomSheet.value) {
          console.warn('Bottom sheet not found');
          return;
        }

        const isSheetElementFocused =
          bottomSheet.value.contains(event.target as HTMLElement) &&
          isFocused(event.target as HTMLElement);

        if (event.key === 'Escape' && !isSheetElementFocused) {
          close();
        }
      });

      const hammerMainInstance = new Hammer(bottomSheetMain.value, {
        inputClass: Hammer.TouchMouseInput,
        recognizers: [[Hammer.Pan, { direction: Hammer.DIRECTION_VERTICAL }]],
      });

      hammerMainInstance.on('panstart panup pandown panend', (e) =>
        dragHandler(e, 'main')
      );

      if (bottomSheetDraggableArea.value) {
        const hammerAreaInstance = new Hammer(bottomSheetDraggableArea.value, {
          inputClass: Hammer.TouchMouseInput,
          recognizers: [[Hammer.Pan, { direction: Hammer.DIRECTION_VERTICAL }]],
        });

        hammerAreaInstance.on('panstart panup pandown panend', (e) =>
          dragHandler(e, 'area')
        );
      } else {
        console.warn('Bottom sheet draggable area not found');
      }
    });

    function open() {
      translateValue.value = 0;
      document.documentElement.style.overflowY = 'hidden';
      document.documentElement.style.overscrollBehavior = 'none';
      showSheet.value = true;
      emit('opened');
    }

    function close() {
      showSheet.value = false;
      translateValue.value = 100;
      setTimeout(() => {
        document.documentElement.style.overflowY = 'auto';
        document.documentElement.style.overscrollBehavior = '';
        emit('closed');
      }, props.transitionDuration * 1000);
    }

    function clickOnOverlayHandler() {
      props.overlayClickClose && close();
    }

    // #region Utils

    /** Convert pixels to view height */
    function pixelToVh(pixel: number) {
      if (typeof props.maxHeight !== 'number') return sheetHeight.value;
      // TODO: Support % and vh units
      const height =
        props.maxHeight && props.maxHeight <= sheetHeight.value
          ? props.maxHeight
          : sheetHeight.value;
      return (pixel / height) * 100;
    }

    function isFocused(element: HTMLElement) {
      return document.activeElement === element;
    }

    // #endregion

    return {
      // refs
      bottomSheet,
      bottomSheetHeader,
      bottomSheetDraggableArea,
      bottomSheetMain,
      bottomSheetFooter,
      bottomSheetContent,
      // Sheet state
      sheetId,
      showSheet,
      sheetContentClasses,
      clickOnOverlayHandler,
      // CSS Variables
      transitionDurationVar,
      sheetHeightVar,
      maxHeightVar,
      translateValueVar,
      maxWidthVar,
      // Controls
      open,
      close,
    };
  },
});
